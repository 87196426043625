const solution = 'dias_steel_decarbon';
const version = '1.3.0';
const snackbar_config = {
  snackbar_duration: 2000,
  snackbar_error_duration: 10000,
};
const bypass_auth_token_resources = ['blob.core.windows.net', '/assets/i18n/', 'api.powerbi.com'];

export const GLOBAL_CONFIG = {
  solution,
  snackbar_config,
  bypass_auth_token_resources,
  version,
};
