import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileRole, ProfileFeature, Tenant } from 'src/app/models/auth/admin-tool.type';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { environment } from 'src/environments/environment';
import { CubeAuth } from '../../modules_msal/CubeAuth';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  token: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  profile: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(
  /*   private readonly msalService: MsalService, */
    private readonly http: HttpClient
  ) {}

  tenants(uniqueId: string): Observable<Array<Tenant>> {
    return this.http.get<Array<Tenant>>(`${environment.admin_tool_url}/users/${uniqueId}/solutions/${environment.solution}/tenants`);
  }

  roles(uniqueId: string, tenant: string): Observable<Array<ProfileRole>> {
    return this.http.get<Array<ProfileRole>>(
      `${environment.admin_tool_url}/tenants/${tenant}/users/${uniqueId}/platforms/CUBE/solutions/${environment.solution}/roles`
    );
  }

  features(role: string, tenant: string): Observable<Array<ProfileFeature>> {
    return this.http.get<Array<ProfileFeature>>(
      `${environment.admin_tool_url}/tenants/${tenant}/roles/${role}/platforms/CUBE/solutions/${environment.solution}/features`
    );
  }

/*   handleRedirectObservable() {
    return this.msalService.handleRedirectObservable();
  } */

  login() {
    /*     this.msalService.loginRedirect();
     */
    CubeAuth.enableADB2C(
      environment.b2cPolicies.tenant,
      environment.b2cPolicies.clientId,
      environment.b2cPolicies.names.signUpSignIn,
      environment.b2cPolicies.redirectUri
    );
    CubeAuth.setLogutRedirectUri(environment.b2cPolicies.postLogoutRedirectUri);

    CubeAuth.onADB2CNewToken = (idToken: any, expires: any, profileInfo: any) => {
      this.token.next(idToken);
      this.profile.next(profileInfo.name);
    };

    CubeAuth.auth();
  }

  logout() {
    /*     this.msalService.logoutRedirect();
     */

    CubeAuth.logout();
    this.profile.next(undefined);
    this.token.next(undefined);
  }

/*   getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  acquireTokenSilent(request: SilentRequest): Observable<AuthenticationResult> {
    return this.msalService.acquireTokenSilent(request);
  } */

/*   acquireTokenRedirect(request: RedirectRequest): void {
    this.msalService.acquireTokenRedirect(request);
  } */

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
/*     const activeAccount = this.getActiveAccount();
    const allAccounts = this.msalService.instance.getAllAccounts();

    if (!activeAccount && allAccounts.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
    } */
  }


  resourceNeedsToken(request_url: string): boolean {
    return environment.bypass_auth_token_resources.every((resource) => !request_url.includes(resource));
  }

/*   tokenRequest(): SilentRequest | RedirectRequest {
    const account = this.getActiveAccount() ?? undefined;

    return {
      account,
      scopes: [],
    };
  } */

  cloneRequest(req: HttpRequest<unknown>, idToken: string, append_token: boolean): HttpRequest<unknown> {
    if (append_token) {
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return clonedReq;
    } else {
      return req;
    }
  }
}
